.profilePageContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.profilePageInnerStuff {
    display: flex;
    flex-direction: column;
    background: rgba(192,0,255,.8);
    background: linear-gradient(187deg, rgba(192,0,255,.8) 0%, rgba(76,205,254,.8) 100%);
    min-width: 1140px;
    margin-top: 60px;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.profileTitle {
    font-family: JohnnyFever;
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0,192, 192), 0 0 12px rgb(0,192, 192), 0 0 18px rgb(0,192, 192);
    font-size: 60px;
    margin-left: 400px;
}

.profileTrackLink {
    display: flex;
    flex-direction: column;
}

.profileTrackContainer {
    display: flex;
    margin-top: 20px;

}

.profileImgNameContainer {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    margin-left: 100px;
    gap: 20px;
}

.profileTrackName {
    display: flex;
    font-size: 40px;
    font-family: JohnnyFever;
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0,192, 192), 0 0 12px rgb(0,192, 192), 0 0 18px rgb(0,192, 192);
}

.trackNav {
    text-decoration: none;

}

.profileButtContainer {
    position: absolute;
    gap: 20px;
    height: 30px;
    margin-left: 320px;
    margin-top: 80px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.profileImgNameContainer {
}

.delTrackBtn {
    background-color: rgb(0, 222, 222);
    box-shadow: 0px 0px 25px 5px rgb(0, 222, 222);
    border-radius: 4px;
    padding: 0;
    border: none;
    font-size: 18px;
    font-family: Zeroesone;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    height: 30px;
    width: 80px;
}

.delTrackBtn:hover {
    background-color: rgb(222, 125, 246);
    box-shadow: 0px 0px 15px 5px rgb(222, 125, 246);
}

.editTrackFormBtn {
    background-color: rgb(0, 222, 222);
    box-shadow: 0px 0px 25px 5px rgb(0, 222, 222);
    border-radius: 4px;
    padding: 0;
    border: none;
    font-size: 18px;
    font-family: Zeroesone;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    height: 30px;
    width: 80px;
    margin-left: 20px;
    text-align: center;
}

.editTrackFormBtn:hover {
    background-color: rgb(222, 125, 246);
    box-shadow: 0px 0px 15px 5px rgb(222, 125, 246);
}



#profilehr {
    margin-left: 60px;
}

#profileTrackImages {
    width: 200px;
    height: 200px;
}
