.homePageContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
}

.homePageInnerStuffContainer {
    display: flex;
    flex-direction: column;
    background-color: rgba(69,14,255,.8);
    margin-top: 50px;
    width: 1140px;
    height: 100vh;
}

.recentlyPlayed {
    display: flex;
    margin-left: 120px;
    margin-bottom: 20px;
    padding-top: 50px;
    font-size: 40px;
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0, 222, 222), 0 0 12px rgb(0, 222, 222), 0 0 18px rgb(0, 222, 222);
    font-family: ZeroesOne;
}

.hpLRButtons {
    display: flex;
    position: absolute;
    justify-content: space-between;
    margin-top: 210px;
}

.hpLRButtons > *:hover {
    cursor:pointer;
    color: rgb(196,0,247);
    text-shadow: 0 0 1px rgb(196,0,247), 0 0 12px rgb(196,0,247), 0 0 18px rgb(196,0,247);
}

.fa-chevron-left {
    position: absolute;
    font-size: 100px;
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0, 222, 222), 0 0 12px rgb(0, 222, 222), 0 0 18px rgb(0, 222, 222);
    margin-left: 20px;
}

.fa-chevron-right {
    position: absolute;
    font-size: 100px;
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0, 222, 222), 0 0 12px rgb(0, 222, 222), 0 0 18px rgb(0, 222, 222);
    margin-left: 1055px;
}

.track {
    width: 200px;
}

.tracks {
    display: grid;
    margin-left: 100px;
    max-width: 900px;
    grid-gap: 100px;
    padding: 16px;
    grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(160px,1fr);
    overflow-x: auto;
    scroll-behavior: smooth;
    overflow: hidden;
    box-shadow: 0px 0px 65px rgb(0, 222, 222);
    border: 1px solid rgb(0, 144, 144);
    border-radius: 5px;
}

.tracksPageTrackImage {
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
    box-shadow: 0px 0px 25px rgb(228, 121, 255);
    border-radius: 5px;
}

.trackName {
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 20px;
    width: 200px;
    font-family: JohnnyFever;
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0, 222, 222), 0 0 12px rgb(0, 222, 222), 0 0 18px rgb(0, 222, 222);
}

.hr {
    height: 1px;
    margin-top: 250px;
    margin-left: 50px;
    width: 1000px;
    border-top: 3px solid rgb(69,14,255,.8);
    box-shadow: 0px 0px 25px 5px rgb(69,14,255,.8);
}
