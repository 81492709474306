.emPassContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    font-size: 30px;
    font-family: JohnnyFever;
}

.emailField {
    font-size: 20px;
    margin-left: 10px;
    border-radius: 5px;
}

.passwordField {
    font-size: 20px;
    margin-left: 10px;
    border-radius: 5px;
}

.loginSubButt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 0;
    border: none;
    background-color: rgb(196,0,247);
    box-shadow: 0px 0px 15px 5px rgb(196,0,247);
    border-radius: 15px;
    width: 120px;
    height: 40px;
    align-items: center;
    margin-left: 150px;
    font-size: 20px;
    font-family: JohnnyFever;
    letter-spacing: 2px;
    cursor: pointer;
}

.loginSubButt:hover {
    background-color: rgb(222, 125, 246);
    box-shadow: 0px 0px 15px 5px rgb(222, 125, 246);
    letter-spacing: 2px;
}

#uploadhr {
    margin-left: 60px;
    margin-top: 20px;
}
