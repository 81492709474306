/* TODO Add site wide styles */
html {
    min-height: 100%;
}

body {
    background-image: url("./images/bgimg.jpg");
    margin: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

img {
    object-fit: fill;
}

