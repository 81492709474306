.singleTrackPageContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.singleTrackInnerStuffContainer {
    display: flex;
    flex-direction: column;
    background: rgba(192,0,255,.8);
    background: linear-gradient(187deg, rgba(192,0,255,.8) 0%, rgba(76,205,254,.8) 100%);
    min-width: 1140px;
    margin-top: 60px;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.singleTrackDetails {
    margin-top: 100px;
}

/* THIS IS ON PURPOSE.  NOT EVERYONE WANTS AN IMAGE ON THEIR SONG. */
.singleTrackImage {
    min-width: 250px;
    min-height: 250px;
    max-width: 250px;
    max-height: 250px;
    margin-left: 850px;
    margin-top: -110px;
    box-shadow: 0px 0px 25px 15px rgb(34, 0, 112);
}

.waveformContainer {
    display: flex;
    flex-direction: column;
    margin-top: -150px;
}

.waveform {
    width: 780px;
    position: absolute;
    margin-left: 15px;
    margin-top: 30px;
}

.playPause {
    display: flex;
    position: absolute;
    margin-top: -80px;
    margin-left: 30px;
    font-size: 70px;
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0, 222, 222), 0 0 12px rgb(0, 222, 222), 0 0 18px rgb(0, 222, 222);
    background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
}

.singleTrackName {
    font-size: 40px;
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0, 222, 222), 0 0 12px rgb(0, 222, 222), 0 0 18px rgb(0, 222, 222);
    font-family: ZeroesOne;
    margin-left: 120px;
    margin-top: 0px;
    width: 800px;
    overflow-wrap: break-word;
}

.singleTrackArtist {
    font-size: 60px;
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0, 222, 222), 0 0 12px rgb(0, 222, 222), 0 0 18px rgb(0, 222, 222);
    font-family: ZeroesOne;
    margin-left: 120px;
    margin-top: -80px;
}


.playPause:hover {
    color: rgb(0, 192, 192);
    text-shadow: 0 0 1px rgb(0,192, 192), 0 0 12px rgb(0,192, 192), 0 0 18px rgb(0,192, 192);
}

.commentContainer {
    display: flex;
    margin-left: 55px;
    margin-top: 130px;
    flex-direction: column-reverse;
}

.commentDetContainer {
    margin-top: 40px;
    font-family: JohnnyFever;
}

.commentUser {
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0,192, 192), 0 0 12px rgb(0,192, 192), 0 0 18px rgb(0,192, 192);
    font-size: 20px;
}

.commentContent {
    color: rgb(0, 222, 222);
    text-shadow: 0 0 1px rgb(0,192, 192), 0 0 12px rgb(0,192, 192), 0 0 18px rgb(0,192, 192);
    font-size: 30px;
    overflow-wrap: break-word;
    max-width: 1000px;
}

.createCommentContainer {
    position: absolute;
}

.delComBtn {
    background-color: rgb(0, 222, 222);
    box-shadow: 0px 0px 25px 5px rgb(0, 222, 222);
    border-radius: 4px;
    padding: 0;
    border: none;
    font-size: 18px;
    font-family: Zeroesone;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    height: 20px;
    width: 80px;
    margin-left:20px;
    margin-top: 5px;
    text-align: center;
    padding-bottom: 20px;
}

.delComBtn:hover {
    background-color: rgb(222, 125, 246);
    box-shadow: 0px 0px 15px 5px rgb(222, 125, 246);
}

.newCommentForm {
    margin-left: 55px;
}

.commentField {
    width: 800px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 15px 5px rgb(222, 125, 246);
    font-size: 20px;
    font-family: JohnnyFever;
    letter-spacing: 2px;
}

.allCommentStuff {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}

.commenthr {
    height: 1px;
    margin-bottom: 10px;
    width: 1000px;
    border-top: 3px solid rgb(69,14,255,.8);
    box-shadow: 0px 0px 25px 5px rgb(69,14,255,.8);
}

.subButt {
    width: 200px;
    color: black;
    margin-left: -0px;
}

.errors {
    font-size: 14px;
    font-family: Zeroesone;
    letter-spacing: 2px;
}

.comment {
    margin-top: -20px;
}

.editSubmit {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.commentForm {
    display: flex;
    height: 100px;
    width: 300px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 15px 5px rgb(222, 125, 246);
    font-size: 20px;
    font-family: JohnnyFever;
}

.editSubButt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 0;
    border: none;
    background-color: rgb(196,0,247);
    box-shadow: 0px 0px 15px 5px rgb(196,0,247);
    border-radius: 15px;
    width: 120px;
    height: 40px;
    align-items: center;
    font-size: 20px;
    font-family: JohnnyFever;
    letter-spacing: 2px;
    cursor: pointer;
}

.editSubButt:hover {
    background-color: rgb(222, 125, 246);
    box-shadow: 0px 0px 15px 5px rgb(222, 125, 246);
    letter-spacing: 2px;
}

.editModalDesc {
    font-size: 20px;
    font-family: JohnnyFever;
    margin-top: 10px;
}
