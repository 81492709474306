#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(25, 241, 241, 0.7);
    box-shadow: 0px 0px 25px 5px rgb(25, 241, 241);
    width: 500px;
    height: 300px;
    border-radius: 5px;
}

.modalChildren {
    margin-top: 10px;
    gap: 10px;
}
