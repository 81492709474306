cdcf.splashPageContainer {
    display: flex;
    margin-top: 70px;
}

.splashDescContainer {
    display: flex;
    width: 100%;
    margin-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: space-between;
}

.enterSiteContainer {
    display: flex;
    flex-direction: column;
}

.splashDesc {
    display: flex;
    width: 600px;
    font-family: ZeroesOne;
    text-align: left;
    font-size: 50px;
    color: rgb(0, 202, 202);
    text-shadow: 0 0 1px rgb(0, 202, 202), 0 0 12px rgb(0, 202, 202), 0 0 18px rgb(0, 202, 202);
}

.splashSignUpLink {
    width: 300px;
}

.splashBtn {
    display: flex;
    width: 300px;
    overflow: hidden;
    position: static;
    text-align: center;
    white-space: nowrap;
    height: 70px;
    border-radius: 24px;
    cursor: pointer;
    background-color: rgb(0, 202, 202);
    box-shadow: 0px 0px 25px rgb(0, 202, 202);
    color: black;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    gap: 10px;
    margin-right: 120px;
    margin-top: 30px;
    font-family: JohnnyFever;
    text-decoration: none;
    font-size: 30px;
    letter-spacing: 2px;
}

.splashBtn:hover {
    background-color: rgb(196,0,247);
    box-shadow: 0px 0px 25px rgb(196,0,247);
    letter-spacing: 2px;
}

.splashDemoBtn {
    border: none;
}

.loginbtn {
    margin-top: 500px;
    display: flex;
    width: 300px;
    overflow: hidden;
    position: static;
    text-align: center;
    white-space: nowrap;
    padding: 0 36px;
    height: 70px;
    border-radius: 24px;
    cursor: pointer;
    background-color: rgb(0, 202, 202);
    box-shadow: 0px 0px 25px rgb(0, 202, 202);
    color: black;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    gap: 10px;
    margin-right: 60px;
    margin-top: 30px;
    font-family: JohnnyFever;
    text-decoration: none;
    font-size: 30px;
    letter-spacing: 2px;
    padding: 0;
    border: none;
}

.loginbtn:hover {
    background-color: rgb(196,0,247);
    box-shadow: 0px 0px 25px rgb(196,0,247);
    letter-spacing: 2px;
}

.signUpbtn {
    margin-top: 500px;
    display: flex;
    width: 300px;
    overflow: hidden;
    position: static;
    text-align: center;
    white-space: nowrap;
    padding: 0 36px;
    height: 70px;
    border-radius: 24px;
    cursor: pointer;
    background-color: rgb(0, 202, 202);
    box-shadow: 0px 0px 25px rgb(0, 202, 202);
    color: black;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    gap: 10px;
    margin-right: 60px;
    margin-top: 30px;
    font-family: JohnnyFever;
    text-decoration: none;
    font-size: 30px;
    letter-spacing: 2px;
    padding: 0;
    border: none;
}

.signUpbtn:hover {
    background-color: rgb(196,0,247);
    box-shadow: 0px 0px 25px rgb(196,0,247);
    letter-spacing: 2px;
}
